<!--レポートの選択用コンボボックスとデータ表示を行うテーブルのコンポーネントです。-->
<template>
  <Loading v-if="loading"></Loading>
  <div v-else id="app">
    <div class="selectarea">
      <v-row justify="start">
        <v-col cols="12" xs="1" sm="12" md="12" lg="8">
          <!--取得年選択コンボ-->
          <div style="text-align: left">
            <img
              class="icons calender_icon"
              src="./icons/calendar.svg"
              alt="calendar"
            />
            <select
              v-model="selectedyear"
              class="selectors select_yearmanth form-select d-inline-block"
              @change="selectYear"
            >
              <option hidden value="" selected>-----</option>
              <option
                v-for="year in years"
                v-bind:value="year"
                v-bind:key="year"
              >
                {{ year + "年" }}
              </option>
            </select>

            <!--取得月選択コンボ-->
            <img
              class="icons arrow_icon"
              src="./icons/chevron-right.svg"
              alt="chevron"
            />
            <select
              v-model="selectedmonth"
              class="selectors select_yearmanth form-select d-inline-block"
              @change="selectMonth"
            >
              <option hidden value="" selected>-----</option>
              <option
                v-for="month in months"
                v-bind:value="month"
                v-bind:key="month"
              >
                {{ month + "月" }}
              </option>
            </select>

            <!--レポート選択コンボ-->
            <img
              class="icons report_icon"
              src="./icons/file-text.svg"
              alt="report"
            />
            <select
              v-model="selectedreport"
              @change="selectReport"
              class="selectors select_report form-select d-inline-block"
            >
              <option hidden value="" selected>-----</option>
              <option
                v-for="list in reportlists"
                v-bind:value="list"
                v-bind:key="list"
              >
                {{ fromatReportname(list) }}
              </option>
            </select>
          </div>
        </v-col>

        <!--表示 グラフ CSV ボタン-->
        <v-col cols="12" xs="1" sm="7" md="7" lg="4">
          <div class="btnarea">
            <v-btn
              class="buttons"
              v-show="selectedreport != '' && selectedmonth != ''"
              @click="showReport"
              >レポート表示</v-btn
            >
            <v-btn
              class="buttons"
              v-if="graphbtnflg && graphuse"
              @click="showGraph"
              >表／グラフ</v-btn
            >
            <v-btn
              class="buttons"
              v-show="csvbtnflg && selectedreport != '' && selectedmonth != ''"
              @click="downloadCSV"
              >CSV</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>

    <!--レポートラベル-->
    <div
      v-show="showarea == 'table' || showarea == 'graph'"
      class="mt-3 bg-light text-left"
    >
      <span id="reportlabel"
        >{{ selectedyear }} 年 {{ selectedmonth }}月　アクセスレポート　
        {{ selectedreportName }}</span
      >
    </div>

    <!--合計テーブル-->
    <div class="tablescroll table-responsive" v-on:scroll="scrollsync">
      <table
        v-show="showarea == 'graph' || showarea == 'table'"
        id="total_table"
        class="reporttable table table-bordered table-hover"
      >
        <thead>
          <tr>
            <td
              class="fw-bold"
              v-for="t_firstrow in t_firstrows"
              :key="t_firstrow"
            >
              {{ t_firstrow }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="date" v-for="t_row in t_rows" :key="t_row">
              {{ t_row }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--詳細テーブル-->
    <div class="tablescroll table-responsive" v-on:scroll="scrollsync">
      <table
        v-show="showarea == 'table'"
        id="detail_table"
        class="reporttable table table-bordered table-hover"
      >
        <thead>
          <tr>
            <td
              class="fw-bold"
              v-for="d_firstrow in d_firstrows"
              :key="d_firstrow"
            >
              {{ d_firstrow }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="date" v-for="d_row in d_rows" :key="d_row">
            <td v-for="(value, key) in d_row" :key="value">
              {{ d_row[key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-show="showarea == 'spinner'"
      id="spinner"
      class="spinner-border text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <ReportGraph
      v-show="showarea == 'graph'"
      :labels="graphlabel"
      :data="graphdata"
    >
    </ReportGraph>
  </div>
</template>
<script>
import CMethod from "@/lib/CommonMethods.js";
import Loading from "../Common/Loading.vue";
import ReportGraph from "@/components/AccessReport/ReportGraph";

export default {
  name: "app",
  components: {
    Loading,
    ReportGraph,
  },
  data() {
    return {
      loading: true,
      years: [],
      months: [],
      reportdata: null,
      reportlists: [],
      selectedyear: "",
      selectedmonth: "",
      selectedreport: "",
      selectedreportName: "",
      reportids: null,
      t_firstrows: null,
      t_rows: null,
      d_firstrows: null,
      d_rows: null,
      graphlabel: null,
      graphdata: null,
      graphuse: true,
      csvbtnflg: false,
      graphbtnflg: false,
    };
  },
  methods: {
    /**
     * 年選択コンボ変更時処理
     * 選択された"年"を設定します。
     */
    selectYear() {
      this.csvbtnflg = false;
      this.graphbtnflg = false;
      this.selectedmonth = "";
      this.selectedreport = "";
      this.months = Object.keys(this.reportdata[this.selectedyear]);
    },

    /**
     * 月選択コンボ変更時処理
     * 選択された"月"を設定します。
     */
    selectMonth() {
      this.csvbtnflg = false;
      this.graphbtnflg = false;
      this.selectedreport = "";
      this.reportlists = this.reportdata[this.selectedyear][this.selectedmonth];
    },

    /**
     * レポート選択コンボ変更時処理
     * レポートまで選択された時、残りのボタンを表示します。
     */
    selectReport() {
      this.csvbtnflg = false;
      this.graphbtnflg = false;
    },

    /**
     * レポートIDからレポート名を特定します。
     */
    fromatReportname(listname) {
      return this.reportids[listname];
    },

    /**
     * 表示ボタン押下時処理
     * 選択されたレポートの集計テーブル、詳細テーブルを表示します。
     */
    async showReport() {
      this.showarea = "spinner";
      this.csvbtnflg = true;
      this.graphbtnflg = true;
      this.selectedreportName = this.reportids[this.selectedreport];
      var date = new Date();
      this.now =
        CMethod.formatDate(date) +
        date.getHours() +
        "時" +
        date.getMinutes() +
        "分";

      // レポートデータ初期化
      this.t_firstrows = null;
      this.t_rows = null;
      this.d_firstrows = null;
      this.d_rows = null;

      // レポート詳細データ取得
      var response = await this.$StarkManager
        .getMember("Morgan")
        .getReportDetail(this.selectedreport);

      this.showarea = "table";
      // 合計テーブルデータ
      this.t_firstrows = response.data.totalreport.firstrow;
      this.t_rows = response.data.totalreport.rows;

      // 詳細テーブルデータ
      this.d_firstrows = response.data.report.firstrow;
      this.d_rows = response.data.report.rows;
    },

    /**
     * 表/グラフボタン押下時処理
     * レポートのテーブルとグラフの表示切替を行います。
     */
    // グラフ表示
    showGraph() {
      if (this.showarea == "table") {
        this.showarea = "graph";
        this.graphlabel = this.t_firstrows.slice(1, -1);
        this.graphdata = this.t_rows.slice(1, -1);
      } else {
        this.showarea = "table";
        this.graphlabel = [];
        this.graphdata = [];
      }
    },

    /**
     * CSVボタン押下時処理
     * 表示されているレポートをCSVにて出力する。
     */
    // CSV出力
    downloadCSV() {
      var csv = "\ufeff";
      this.t_firstrows.forEach((el) => {
        var line = el + ",";
        csv += line;
      });
      csv += "\n";
      this.t_rows.forEach((el) => {
        var line = el + ",";
        csv += line;
      });
      csv += "\n" + "\n";
      this.d_firstrows.forEach((el) => {
        var line = el + ",";
        csv += line;
      });
      csv += "\n";
      this.d_rows.forEach((el) => {
        var line = el + "," + "\n";
        csv += line;
      });
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.selectedreportName + ".csv";
      link.click();
    },

    /**
     * テーブルスクロール処理
     * 集計テーブルと詳細テーブルのスクロールを同期します。
     */
    scrollsync(event) {
      let target = event.target;
      target.parentElement.querySelector(".tablescroll").scrollLeft =
        target.scrollLeft;
    },

    /**
     * アクセスレポートページ表示時初処理
     * 操作案件に紐ずくアクセスレポートを取得します。
     */
    async getReports() {
      // レポートリスト取得
      var response = await this.$StarkManager.getMember("Morgan").getReports();

      this.loading = false;
      this.reportdata = response.data.data;
      this.reportids = response.data.reportIDs;

      //コンボボックスの状態初期化
      this.years = Object.keys(this.reportdata);
      this.selectedyear = this.years.slice(-1)[0];
      this.months = Object.keys(this.reportdata[this.selectedyear]);
      this.selectedmonth = this.months.slice(-1)[0];
      this.reportlists = this.reportdata[this.selectedyear][this.selectedmonth];
      this.selectedreport = this.reportlists[0];
    },
  },
  mounted() {
    // 初期処理（レポートリスト取得）を実行
    this.getReports();
  },
};
</script>

<style>
.selectarea {
  margin: 20px;
  display: flex;
  height: 40px;
}

.calender_icon {
  color: red;
}
.arrow_icon {
  color: red;
}
.report_icon {
  color: red;
}

.icons {
  margin: 4px 15px 3px 10px;
}

.selectors {
  margin: 15px 0 0 0;
  height: 34px;
}

.select_yearmanth {
  width: 14% !important;
}

.select_report {
  width: 48% !important;
}

.btnarea {
  text-align: left;
  margin: 11px 0 0 -110px;
}

.buttons {
  margin: 2px 10px 0 10px;
}

#reportlabel {
  color: #606060;
  font-weight: bold;
  margin: 0 0 0 20px;
}

.reporttable {
  margin: 20px;
  width: 1220px !important;
  font-size: 95%;
  table-layout: fixed;
}

.reporttable td {
  padding: 0.5px !important;
}

table td:last-child {
  width: 50px;
}

thead {
  background-color: #e6f1ff;
}

table tr td:nth-of-type(1) {
  width: 150px;
  word-break: break-all;
  text-align: center;
}

#spinner {
  display: block;
  margin: auto;
  margin-top: 40px;
}

/*画面サイズが小さい場合のスタイル */
@media screen and (max-width: 750px) {
  .selectarea {
    display: grid;
    height: 331px;
  }
  .select_report {
    width: 92px;
    font-size: 15px;
  }

  .select_yearmanth {
    width: 35% !important;
  }
  .icons {
    margin: 5px 15px 3px 10px;
  }
  .select_report {
    margin: 20px 0 20px 0;
    width: 85% !important;
  }

  .buttons {
    width: 89%;
    margin: 8px 0 5px 0;
  }
  .btnarea {
    text-align: center;
    margin: -80px 0 0 0;
  }
}
</style>
