/**
 * Jarvisで利用する設定値をここに定義します。
 */

/** 認証を行うための情報セット*/
const msal = {
  auth: {
    clientId: "3aea97e7-b222-4c7f-859e-34ad4401d485",
    authority:
      "https://jaspcim.b2clogin.com/jaspcim.onmicrosoft.com/B2C_1_default_signin",
    knownAuthorities: ["jaspcim.b2clogin.com"],
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

/** MorganへアクセスするためのURL*/
const morganendpointurl = "https://ach.stream.jfit.co.jp/api";

/** Morganのスコープ（Read）*/
const morganscoperead =
  "https://jaspcim.onmicrosoft.com/24ff7364-cca6-4d54-b5a9-aef5810b275a/tasks.read";

/** Morganのスコープ（Write）*/
const morganscopewrite =
  "https://jaspcim.onmicrosoft.com/24ff7364-cca6-4d54-b5a9-aef5810b275a/tasks.write";

/* ログ表示を制御する情報セット */
const logger = {
  baseConfig: {
    active: true, //ログ出力制御
  },
  consolelogConfig: {
    levelFilter: ["trace", "debug", "info", "warn", "error", "fatal"], // 出力するレベルを定義
    enable: true,
  },
};

export default {
  msal,
  morganendpointurl,
  morganscoperead,
  morganscopewrite,
  logger,
};
