<!--トピック一覧テーブルおよび削除確認ダイアログのコンポーネントです。-->
<template>
  <!-- トピック一覧 -->
  <table fixed-header class="table table-hover topictable">
    <thead>
      <tr class="titles">
        <th class="title-date">最終更新日時</th>
        <th class="title-sub">件名</th>
        <th class="title-status">操作</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="topic in topics"
        :key="topic.id"
        @click="
          TestOpen(topic);
          dialogOpen2 = true;
        "
      >
        <td class="subdate">{{ topic.create_time }}</td>
        <td class="subname">{{ topic.topic }}</td>
        <td style="text-align: left">
          <v-btn icon @click.stop="confirmationDelete(topic)" title="削除">
            <img src="./icons/trash3.svg" alt="delete" />
          </v-btn>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- 削除確認ダイアログ -->
  <v-dialog v-model="deleteDialogOpen">
    <v-card>
      <v-card-title>
        <div>トピック削除</div>
      </v-card-title>
      <v-card-text>
        <p>
          件名：「<b>{{ deletetopic.topic }}</b
          >」<br />
        </p>
        <p>
          本当に削除してよろしいですか？<br />含まれるメッセージとファイルは<b>すべて</b>削除されます。
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="deleteDialogOpen = false" class="btn-secondary"
          >いいえ</v-btn
        >
        <v-btn
          @click="
            deleteDialogOpen = false;
            deleteTopic();
          "
          class="btn-primary"
          >はい</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- メッセージダイアログ -->
  <!--<v-dialog
    id="MM"
    v-model="dialogOpen2"
    @click:outside="reset"
    @keydown.esc="reset"
  >
    <v-card id="MC" class="overflow-auto">
      <v-card-title>
        <div style="margin: 10px">
          {{ modalitem.create_time }}　{{ modalitem.topic }}
        </div>
      </v-card-title>
      <h4 style="margin: 10px">メッセージ</h4>
      <v-divider></v-divider>
      <v-card-text>
        <div>
          <v-textarea v-model="comment" label="メッセージを入力"></v-textarea>
          <v-btn
            icon
            @click="Testselect(modalitem)"
            class="btn-primary"
            style="display: block; margin: -30px 0 10px auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-send"
              viewBox="0 0 16 16"
              transform="rotate(45)"
            >
              <path
                d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"
              /></svg
          ></v-btn>

          <div class="add-filearea" style="display: flex">
            <div class="file" style="width: 96%">
              <v-btn
                v-for="file in fileobj"
                :key="file"
                variant="outlined"
                color="primary"
                style="margin-left: 20px; height: 48px; text-transform: none"
              >
                <img
                  src="./icons/file-earmark.svg"
                  width="16"
                  height="16"
                  alt="file"
                />
                {{ file.name }}
                　{{ file.size }}byte

                <img
                  src="./icons/trash3.svg"
                  @click.stop="removeFile(index)"
                  alt="delete"
                />
              </v-btn>
            </div>
            <div class="inputbtn" style="width: 20%; margin-left: 10px">
              <label class="file-select-labbel">
                添付ファイルを追加
                <input hidden type="file" multiple @change="chooseFile" />
              </label>
            </div>
          </div>
        </div>
        <div>
          <div
            class="comment"
            style="margin-bottom: 20px; margin-top: 10px; display: flex"
            v-for="come in modalitem.comment"
            :key="come"
          >
            <div
              style="
                width: 20%;
                font-size: 12px;
                align-items: flex-end;
                display: flex;
              "
            >
              {{ come.create_user }} <br />{{ come.time }}
            </div>
            <div style="width: 50%" v-show="come.text != ''">
              {{ come.text }}
            </div>
            <div style="width: 30%">
              <span style="text-align: end">
                <v-btn
                  v-for="file in come.files"
                  :key="file"
                  variant="outlined"
                  color="primary"
                  style="margin-left: 20px; height: 48px; text-transform: none"
                >
                  <img
                    src="./icons/file-earmark.svg"
                    width="16"
                    height="16"
                    alt="file"
                    style="margin-right: 5px"
                  />
                  {{ file.originalName }}
                  　{{ file.size }}byte
                </v-btn>
              </span>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="
            dialogOpen2 = false;
            reset();
          "
          class="btn-secondary"
          >キャンセル</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog> -->
  <Dialog :dialogOpen="dialogOpen2" :modalitem="modalitem" />
</template>
<script>
import store from "@/store/index.js";
import axios from "axios";
import Loading from "../Common/Loading.vue";
import Dialog from "./Dialog.vue";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      status: null,
      subjects: null,
      selected: [],

      //TEST
      topics: null,
      modalitem: [],
      dialogOpen: false, //件名
      dialogOpen2: false, //メッセージ
      deleteDialogOpen: false, //削除
      topic: "",
      comment: "",
      deletetopic: "",
    };
  },
  methods: {
    ////// Test ////////
    addTopic() {
      if (this.topic != "") {
        axios
          .post("http://localhost:3000/t_topics", {
            topic: this.topic,
            create_time: new Date().toLocaleString(),
            comment: [],
          })
          .then((response) => {
            console.log(response.data);
            this.getTopics();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    reset() {
      this.topic = "";
      this.comment = "";
      this.fileinfo = [];
      this.fileobj = [];
    },

    Testselect(data) {
      this.getTopics();
      if (this.comment != "" || this.fileobj.length != 0) {
        let comO = data.comment;
        console.log(data.comment);

        let comeData = {
          time: new Date().toLocaleString(),
          text: this.comment,
          create_user: store.getters.user.name,
          files: this.fileinfo,
        };

        comO.push(comeData);

        axios
          .put("http://localhost:3000/t_topics/" + data.id, {
            topic: data.topic,
            create_time: new Date().toLocaleString(),
            comment: comO,
          })
          .then((response) => {
            console.log(response.data);

            this.reset();
            // 全件名再取得し、一覧を更新する
            this.getTopics();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getTopics() {
      axios
        .get("http://localhost:3000/t_topics?_sort=id&_order=desc")
        .then((response) => {
          console.log(response.data);
          this.topics = response.data;
          console.log(this.topics);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    TestOpen(item) {
      this.modalitem = item;
      //document.documentElement.style.overflow = "hidden";
    },

    /**
     * 削除アイコン押下時処理
     * 削除確認ダイアログの表示を行います。
     * 削除するトピックを削除メソッドへリレーするためにdataにセットします。
     */
    confirmationDelete(topic) {
      this.deleteDialogOpen = true;
      this.deletetopic = topic;
    },

    /**
     * トピックを削除します。
     */
    deleteTopic() {
      axios
        .delete("http://localhost:3000/t_topics/" + this.deletetopic.id)
        .then((response) => {
          console.log(response.data);
          this.deletetopic = "";
          // 全件名再取得し、一覧を更新する
          this.getTopics();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    // 件名一覧取得
    this.getTopics();
  },
};
</script>

<style>
.topictable {
  width: 80% !important;
  margin: 20px;
}
.topictable tr :hover,
.delete-icon {
  cursor: pointer;
}

.subdate {
  text-align: left !important;
  width: 408px !important;
  margin: 20px 0 0 0;
}
.subname {
  text-align: left !important;
  width: 545px;
}
.titles {
  text-align: left;
  background-color: white;
}
.comment {
  text-align: left;
  margin: 20px 0 -20px 0;

  /*TODO 仮*/
  border-bottom: solid 1px rgb(216, 214, 214);
}

/*TODO 仮*/
table td:last-child {
  width: 195px;
}
.bc {
  background-color: cornflowerblue;
}

.upload-btn {
  color: white !important;
  background-color: #41b883;
}
.drag-area {
  min-height: 300px;
  max-width: 500px;
  width: 100%;
  border: 1px dashed #ced4da;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enter.drag-area {
  border: 2px dashed #1867c0;
  background: #d4e8ff;
}
.drag-title {
  color: cornflowerblue;
}
.send-file-info {
  padding: 20px 0 0 0 !important;
}
.file-select-labbel {
  margin: 6px 0;
  padding: 10px 21px;
  border-radius: 6px;
  color: #ffffff;
  background-color: #1564d9;
  cursor: pointer;
}

#MC {
  width: 1050px;
  height: 880px;
  /* height: auto; */
}

.v-dialog .v-overlay__content {
  max-height: 930px;
  min-width: 240px;
  max-width: 1050px;
  border-radius: 4px;
}
</style>
