<!--フッターのコンポーネントです。-->
<template>
  <div id="footer">
    <div id="copyright">Copyright © J・FIT Co.,LTD All rights reserved.</div>
    <div id="designed">Designed by J・FIT</div>
  </div>
</template>

<script></script>

<style>
#footer {
  border-top: 2px solid #999999;
  margin-top: 10%;
  padding: 0px;
}
#copyright {
  margin: 0px;
  padding: 5px 10px;
  text-align: left;
  font-size: 85%;
  font-weight: normal;
  font-style: italic;
  color: #666666;
  float: left;
}
#designed {
  margin: 0px;
  padding: 5px 10px;
  text-align: right;
  font-size: 85%;
  font-weight: normal;
  font-style: italic;
  color: #666666;
}
</style>
