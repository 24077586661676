<!--ログ画面-->
<template>
  <div class="log">
    <v-card class="bg-primary text-light text-left">ログ表示</v-card>
  </div>
</template>

<script>
import store from "../store";
export default {
  name: "Log",
  computed: {
    getname() {
      return store.getters.name;
    },
  },
};
</script>
