import config from "@/Config.js";
import store from "@/store/index.js";
import axios from "axios";
/**
 *Morganへアクセスするクラスです。
 */
export default class MorganAccessor {
  constructor(logger, config) {
    this.logger = logger;
    this.config = config;
    this.stark = null;
  }

  /**
   *StarkManagerへの参照をセットします。
   */
  setStark(stark) {
    this.stark = stark;
  }

  /** 
  /////////////////////////////////////////////
  // Morgan リソース構成再検討に伴い再実装中
  /////////////////////////////////////////////
*/
  async fetchUserBelong() {
    var response = await this.getMorganResponse("Get", "userbelong");

    if (response != null) {
      /// Vuexのstoreの構成も変更を行う
      /// 現状プロジェクトと対応するプロジェクトを別々に保持していたが「belongProjects」にまとめる。
      store.commit("belongProjects", response.data.belongProjects);
      store.commit("currentProject", response.data.defaultProject);
    } else {
      alert("ユーザー所属情報の取得に失敗しました。");
    }
  }

  /**
   *ログインユーザーに紐づく案件情報を取得し、
   *取得した案件情報をローカルストレージに保存します。
   */
  async fetchProject() {
    var response = await this.getMorganResponse("Get", "Project", "");

    if (response != null) {
      store.commit("allowedProjects", response.data.projects);
      store.commit("currentProject", response.data.defaultProject);
    } else {
      alert("案件情報の取得に失敗しました。");
    }
  }

  /**
   * 顧客情報を取得し、取得した顧客情報をローカルストレージに保存します。
   */
  async fetchClient() {
    var projectId =
      store.getters.currentProject.projectId == undefined
        ? ""
        : store.getters.currentProject.projectId;

    var response = await this.getMorganResponse("Get", "Client", projectId);

    if (response != null) {
      store.commit("Client", response.data);
    } else {
      alert("顧客情報の取得に失敗しました。");
    }
  }

  /**
   * サイドバーに表示するメニュー項目を取得し、
   * 取得したメニュー項目をローカルストレージに保存します。
   */
  async fetchMenu() {
    var projectId =
      store.getters.currentProject.projectId == undefined
        ? ""
        : store.getters.currentProject.projectId;

    var response = await this.getMorganResponse("Get", "Menu", projectId);

    if (response != null) {
      store.commit("sideBarList", response.data);
    } else {
      alert("メニュー項目の取得に失敗しました。");
    }
  }

  /**
   * 操作中の案件に紐づくレポート一覧を取得します。
   *@return reportList レポート一覧を返します。
   */
  async getReports() {
    var reportList = await this.getMorganResponse(
      "Get",
      "Report",
      store.getters.currentProject.projectId
    );

    if (reportList != null) {
      return reportList;
    } else {
      alert("レポートの取得に失敗しました。");
    }
  }

  /**
   * アクセスレポート画面で指定したレポートの値を取得します。
   *@param targetreport 指定したレポート
   *@return reportDetail レポートの値を返します。
   */
  async getReportDetail(targetreport) {
    var reportDetail = await this.getMorganResponse(
      "Get",
      "Report",
      store.getters.currentProject.projectId + "/reports/" + targetreport
    );
    if (reportDetail != null) {
      return reportDetail;
    } else {
      alert("レポート詳細の取得に失敗しました。");
    }
  }

  /**
   * 操作中の案件に紐づくイベント一覧を取得します。
   *@return 操作中案件に紐づくイベント一覧を返します。
   */
  async getEvent() {
    var eventList = await this.getMorganResponse(
      "Get",
      "Event",
      store.getters.currentProject.projectId
    );
    if (eventList != null) {
      return eventList;
    } else {
      alert("イベント詳細の取得に失敗しました。");
    }
  }

  /**
   * ライブボタン切替画面で指定したイベントのライブボタン状態を更新します。
   *@param body イベントへのリクエスト内容
   */
  async postEvent(body) {
    var result = await this.getMorganResponse(
      "Post",
      "Event",
      store.getters.currentProject.projectId + "/events",
      body
    );
    // 更新結果文字列を返します。
    return result.statusText;
  }

  /**
   * 呼び出すURLを生成します。
   *@param target 呼び出すコントローラーパス
   *@param appendix パラメーター
   *@return WebAPIを呼び出すためのURLを返します。
   */
  buildUrl(target, appendix) {
    var baseurl = this.config.endpointSuffix;
    return baseurl + "/" + target + "/" + appendix;
  }

  /**
   * リクエストヘッダを構成します。
   * 同じヘッダ内容を複数記載しなくて良いように実装しています。
   *@param  indivHeader リクエストごとに個別で付与するヘッダ
   *@return indivHeader 各リクエスト共通で付与するヘッダを加えて返します。
   */
  mergeCommonHeader(indivHeader) {
    //ここで共通のヘッダを追加する
    indivHeader.headers["Content-Type"] = "application/json";
    return indivHeader;
  }

  /**
   * Moganへアクセスし、その応答結果を取得します。
   *@param method メソッドの識別名
   *@param target 呼び出すコントローラーパス
   *@param param パラメーター
   *@param body リクエストボディ
   *@return response Morganからの応答結果を返します。
   */
  async getMorganResponse(method, target, param, body) {
    switch (method) {
      // Gst リクエスト
      case "Get":
        var token = await this.stark
          .getAuthManager()
          .getToken([config.morganscoperead]);
        return await axios
          .get(
            this.buildUrl(target, param),
            this.mergeCommonHeader({
              headers: {
                Authorization: `Bearer ${token.accessToken}`,
              },
            })
          )
          .then(
            function (response) {
              this.logger.info(
                "RequestSuccess",
                this.logger.objBuilder(
                  method,
                  store.getters.user.username,
                  this.buildUrl(target, param),
                  response.data,
                  ""
                )
              );
              return response;
            }.bind(this)
          )
          .catch(
            function (error) {
              this.logger.error(
                "RequestFaild",
                this.logger.objBuilder(
                  method,
                  store.getters.user.username,
                  this.buildUrl(target, param),
                  error,
                  ""
                )
              );
              return null;
            }.bind(this)
          );

      // Post リクエスト
      case "Post":
        var token = await this.stark
          .getAuthManager()
          .getToken([config.morganscoperead, config.morganscopewrite]);
        return await axios
          .post(
            this.buildUrl(target, param),
            body,
            this.mergeCommonHeader({
              headers: {
                Authorization: `Bearer ${token.accessToken}`,
              },
            })
          )
          .then(
            function (response) {
              this.logger.info(
                "RequestSuccess",
                this.logger.objBuilder(
                  method,
                  store.getters.user.username,
                  this.buildUrl(target, param),
                  response.data,
                  body
                )
              );
              return response;
            }.bind(this)
          )
          .catch(
            function (error) {
              this.logger.error(
                "RequestSuccess",
                this.logger.objBuilder(
                  method,
                  store.getters.user.username,
                  this.buildUrl(target, param),
                  error,
                  body
                )
              );
            }.bind(this)
          );
    }
  }
}
