<!--サインアウトボタンのコンポーネントです。-->
<template>
  <v-menu close-on-content-click>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" id="btn" class="btn-primary" title="アカウント">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-person-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
          />
        </svg>
      </v-btn>
    </template>
    <v-list
      style="
        position: absolute;
        left: -100px;
        z-index: 999;
        width: 290%;
        border: solid 1px rgb(237, 237, 237);
        border-radius: 5px;
      "
      color="#ebebeb"
    >
      <v-list-item>
        <v-list-item-title>
          <div @click="transferTop" role="button">
            <b>{{ myname }}</b>
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isMultipleProjects">
        <v-list-item-title>
          <div role="button">
            <ProjectSwitchButton />
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <div @click="logout" role="button"><SignOutButton /></div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import store from "@/store/index.js";
import ProjectSwitchButton from "../Common/ProjectSwitchButton.vue";
import SignOutButton from "../Common/SignOutButton.vue";
export default {
  name: "AccountButton",
  components: { ProjectSwitchButton, SignOutButton },
  computed: {
    myname() {
      return store.getters.user.name;
    },
    isMultipleProjects() {
      return 2 <= this.$store.getters.allowedProjects.length;
    },
  },
};
</script>
<style>
#btn {
  margin-right: 15px;
}
.dropdown-menu {
  left: 0;
  position: absolute;
  z-index: 1000;
}
</style>
