<!--サインイン後初期画面-->
<template>
  <div class="welcome">
    <v-card class="bg-primary text-light text-left">WELCOME</v-card>
    <v-card id="explanation" class="text-left">
      <td>←左側メニューより選択してください。</td>
      <ul>
        <li>
          ライブアクセスレポート、VODアクセスレポートともに24時間前までのアクセスが集計されます。
        </li>
        <li>
          ブラウザはMicrosoft Edge、Chrome、Firefoxの最新版をお使いください。
        </li>
        <li>
          印刷はブラウザの印刷機能を使います、A4横用紙に最適化しています。
        </li>
        <li>
          表示内容と同じデータがCSVファイルでダウンロードできますのでEXCEL等の表計算ソフトでもご利用いただけます。
        </li>
      </ul>
    </v-card>
  </div>
</template>

<script>
import store from "../store";
export default {
  name: "Welcome",
  computed: {
    getname() {
      return store.getters.name;
    },
  },
};
</script>
