<template>
  <v-app>
    <SideBar />
    <v-main>
      <router-view />
    </v-main>
    <Fotter />
  </v-app>
</template>

<script>
import SideBar from "./components/Common/SideBar.vue";
import Fotter from "./components/Common/Fotter.vue";
export default {
  components: { SideBar, Fotter },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
