<!--固定ヘッダーおよびサイドバーメニューのコンポーネントです。-->
<template>
  <div id="inspire">
    <v-app-bar app v-if="displayHeader">
      <v-btn icon @click="drawer = !drawer">
        <img src="./icons/list.svg" alt="list" />
      </v-btn>
      <div>{{ myprojectname }} さま マイページ</div>
      <!-- <div v-if="isMultipleProjects"><ProjectSwitchButton /></div> -->
      <v-spacer></v-spacer>
      <!-- <div>ユーザー：{{ myname }}　</div> -->
      <AccountButton />
      <!-- <SignOutButton /> -->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app v-if="displayHeader">
      <v-list v-for="nav_list in nav_lists" :key="nav_list">
        <div v-if="nav_list.enabled">
          <v-list-item class="category fw-bold">
            {{ nav_list.category }}
          </v-list-item>
          <template v-for="content in nav_list.contents" :key="content">
            <v-list-item v-if="content.enabled">
              <div v-if="content.route == ''">
                <form
                  name="privatepage"
                  action=""
                  method="post"
                  target="_blank"
                >
                  <input type="hidden" name="AccessToken" value="" />
                  <a class="link-primary" @click="linkClick()">
                    {{ content.title }}</a
                  >
                </form>
              </div>
              <div v-else>
                <a @click="routing(content.route)" class="link-primary">
                  {{ content.title }}
                </a>
              </div>
            </v-list-item>
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import store from "@/store/index.js";
import router from "@/router/index.js";
import SignOutButton from "@/components/Common/SignOutButton.vue";
import AccountButton from "@/components/Common/AccountButton.vue";
import CMethod from "@/lib/CommonMethods.js";
import ProjectSwitchButton from "@/components/Common/ProjectSwitchButton.vue";

export default {
  name: "SideBar",
  components: { SignOutButton, ProjectSwitchButton, AccountButton },
  data() {
    return {
      drawer: null,
    };
  },
  methods: {
    /**
     * メニューバーリンク押下時
     * 選択されたページへルーティングします。
     */
    routing(address) {
      router.push(
        "/" + address,
        () => {},
        () => {}
      );
      if (CMethod.isMobile()) {
        this.drawer = null;
      }
    },
    // 事前確認ページ遷移時
    linkClick() {
      // 取得したトークンと遷移URLを元にsubmit
      privatepage.AccessToken.value = store.getters.token;
      privatepage.action = store.getters.privateUrl;
      this.$Logger.info("AccessToken", privatepage.AccessToken.value);
      this.$Logger.info("URL", privatepage.action);
      privatepage.submit();
    },
  },
  computed: {
    myname() {
      return store.getters.user.name;
    },
    myprojectname() {
      return this.$store.getters.currentProject.projectName;
    },
    isMultipleProjects() {
      return 2 <= this.$store.getters.allowedProjects.length;
    },
    displayHeader() {
      return store.getters.displayHeader;
    },
    nav_lists() {
      return store.getters.sideBarList;
    },
  },
};
</script>
<style>
.category {
  background-color: #e6f1ff;
}
.v-application__wrap {
  margin-bottom: 100px;
  min-height: auto;
}
.bi-list {
  margin: 0 5px 0 5px;
  width: 35px;
  height: 60%;
  color: #58595b;
}
.link-primary {
  cursor: pointer;
  text-decoration: none;
}
</style>
