<template>
  <div id="app">
    <v-btn @click="dialogOpen = true" color="lime">トピックを作成</v-btn>
    <Dialog :dialogOpen="dialogOpen" :dialogitem="dialogitem" />
  </div>
</template>

<script>
import Dialog from "./Dialog.vue";

export default {
  components: { Dialog },
  data() {
    return {
      dialogOpen: false,
      dialogitem: null,
    };
  },
};
</script>

<style></style>
