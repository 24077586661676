<!--モバイルでアクセスされた際の
ライブボタン切替テーブルのコンポーネントです。（実装中）-->
<template>
  <Loading v-if="loading"></Loading>
  <div v-else id="app">
    <v-card
      v-for="(item, key) in eventinfo"
      :key="key"
      v-bind:class="{ live_valid: item.eventActivePublic }"
      class="card"
    >
      <v-card-header class="cardheader">
        {{ formatDate(item.eventDate) }}<br />
        {{ item.roomLabel }}
        {{ item.eventSeriesLabel }}
      </v-card-header>
      <v-card-text v-bind:class="{ valid: item.eventActivePublic }">
        <span v-if="item.eventActivePublic" class="c_status valid bold"
          >有効
          <img
            width="25"
            height="25"
            class="status_icon"
            src="./icons/camera-video.svg"
            alt="video-on"
        /></span>
        <span v-else class="c_status"
          >無効<img
            width="25"
            height="25"
            class="status_icon"
            src="./icons/camera-video-off.svg"
            alt="video-off"
          />
        </span>
      </v-card-text>
      <v-btn
        v-if="item.eventActivePublic"
        type="button"
        class="control_button btn btn-secondary"
        v-on:click="liveOff(item)"
      >
        無効にする
      </v-btn>
      <v-btn
        v-else
        type="button"
        class="control_button btn btn-primary"
        v-on:click="liveOn(item)"
        data-bs-toggle="modal"
        data-bs-target="#controlModal"
      >
        有効にする
      </v-btn>
      <v-card-text>
        <form name="{{item.roomId}}" action="" method="post" target="_blank">
          <input type="hidden" name="AccessToken" value="" />
        </form>
        <a class="checklink" @click="openLivePage(item)">再生ページ</a>
        <a class="checklink" @click="openTopPage()">トップページ</a>
      </v-card-text>
    </v-card>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="controlModal"
    tabindex="-1"
    aria-labelledby="controlModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="controlModalLabel">確認</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-left">
          会議室： {{ modalitem.roomLabel }}　({{ modalitem.roomTitle }})<br />

          開催日： {{ formatDate(modalitem.eventDate) }}<br />
          会議名： {{ modalitem.eventSeriesLabel }}<br />

          <div v-if="this.date != modalitem.eventdate" class="mt-2 mb-2">
            <span class="text-dark fw-bold bg-primary bg-opacity-10">
              <img
                src="./icons/exclamation-triangle-fill.svg"
                width="16"
                height="16"
                alt="exclamation" />
              この会議室に割り当てられている会議は、本日開催の会議ではありません。<br
            /></span>
          </div>
          ライブボタンを
          <span class="text-primary fw-bold">有効</span> にします。<br />
          続行しますか？<br />
        </div>
        <div class="modal-footer">
          <v-btn
            v-on:click="flagUpdate"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            はい
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            いいえ
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CMethod from "@/lib/CommonMethods.js";
import LiveBtnControl from "../../views/LiveBtnControl.vue";
import store from "@/store/index.js";
import Loading from "../Common/Loading.vue";

export default {
  components: { LiveBtnControl, Loading },
  data() {
    return {
      name: "tetstste",
      loading: true,
      eventinfo: [],
      modalitem: [],
      select: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    openLivePage(e) {
      if (e.eventActivePublic) {
        window.open(
          store.getters.publicUrl + "?tpl=play_live&room_id=" + e.roomId,
          "_blank"
        );
      } else {
        document.forms[e.roomId].AccessToken.value = store.getters.token;
        document.forms[e.roomId].action =
          store.getters.privateUrl + "?tpl=play_live&room_id=" + e.roomId;
        document.forms[e.roomId].submit();
      }
    },
    openTopPage() {
      window.open(store.getters.publicUrl, "_blank");
    },
    liveOn(e) {
      this.select = e;
      this.modalitem = e;
    },
    liveOff(e) {
      this.select = e;
      this.flagUpdate();
    },
    // イベント状態を更新する
    async flagUpdate() {
      // ボタン状態変更
      this.select.eventActivePublic = !this.select.eventActivePublic;

      //要求（イベントIDと制御フラグを送信）
      const body = [
        {
          eventId: this.select.eventId,
          eventActivePublic: this.select.eventActivePublic,
        },
      ];

      var result = await this.$StarkManager.getMember("Morgan").postEvent(body);

      // 更新失敗時
      if (result != "OK") {
        // 状態を戻す
        this.select.eventActivePublic = !this.select.eventActivePublic;
        alert("ライブボタンの変更ができませんでした。");
      }
    },
    formatDate(date) {
      return CMethod.formatDate(date);
    },
    // イベント一覧取得
    async getEvent() {
      var response = await this.$StarkManager.getMember("Morgan").getEvent();
      this.loading = false;
      this.eventinfo = response.data;
    },
  },
  mounted() {
    // イベント一覧取得
    this.getEvent();
  },
};
</script>

<style>
.card {
  margin: 10px;
}

.cardheader {
  margin: auto;
}

.checklink {
  margin: 30px;
}

.c_status {
  font-size: 20px;
}

.status_icon {
  margin: 0 0 0 8px;
}

.live_valid {
  font-weight: bold;
  background-color: #e6f1ff;
}

.valid {
  color: blue;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.modal-open {
  padding-right: 0 !important;
}
.control_button {
  margin: 0 10px 0 10px;
}
</style>
