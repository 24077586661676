/**
 * ログ出力を行うクラスです。
 */
export default class Logger {
  constructor(config, outputs) {
    this.config = config;
    this.outputs = outputs;
  }

  /**
   * ログレベルに応じた出力メソッドを呼び出します。
   * @param msg メッセージ
   * @param value 値
   */
  trace(msg, value) {
    this.writeLog("trace", msg, value);
  }
  debug(msg, value) {
    this.writeLog("debug", msg, value);
  }
  info(msg, value) {
    this.writeLog("info", msg, value);
  }
  warn(msg, value) {
    this.writeLog("warn", msg, value);
  }
  error(msg, value) {
    this.writeLog("error", msg, value);
  }
  fatal(msg, value) {
    this.writeLog("fatal", msg, value);
  }

  /**
   * 出力機能クラスのメソッドを呼び出します。
   * @param level ログレベル
   * @param msg メッセージ
   * @param value 値
   */
  writeLog(level, msg, value) {
    if (this.config.active) {
      this.outputs.map(function (out) {
        switch (level) {
          case "trace":
            out.trace(msg, value);
            break;
          case "debug":
            out.debug(msg, value);
            break;
          case "info":
            out.info(msg, value);
            break;
          case "warn":
            out.warn(msg, value);
            break;
          case "error":
            out.error(msg, value);
            break;
          case "fatal":
            out.fatal(msg, value);
            break;
        }
      });
    }
  }

  /**
   * ログに表示するオブジェクトを構成します。
   *@param method 実行されたメソッド
   *@param uRL アクセス先のURL
   *@param header ヘッダー
   *@param payload ペイロード
   *@param value 応答結果
   */
  objBuilder(method, user, url, value, payload) {
    var logObj = {
      method: method,
      request_user: user,
      request_url: url,
      payload: payload,
      result: value,
    };
    return logObj;
  }
}
