/**
 * Starkの構成要素（例：Morgan 等）へのアクセサーを管理するためのクラスです。
 */
export default class StarkManager {
  constructor(auth, member) {
    this.AuthManager = auth;
    this.member = member;
  }

  /**
   * Stark内のmemberを取得します。
   * @param  memberName member の名前
   * @return  member 名前に対応する member を返します。
   */
  getMember(memberName) {
    var member = this.member[memberName];
    member.setStark(this);
    return member;
  }

  /**
   *AuthManagerのインスタンスを取得します。
   * @return AuthManager を返します。
   */
  getAuthManager() {
    return this.AuthManager;
  }
}
