import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    isSignin: false,
    user: {},
    info: {},
    allowedProjects: {},
    currentProject: {},
    displayHeader: false,
    sideBarList: {},
  },
  mutations: {
    SignIn(state, user) {
      state.isSignin = true;
      state.user = user;
    },
    SignOut(state) {
      state.isSignin = false;
      state.displayHeader = false;
      state.user = {};
      state.info = {};
      state.allowedProjects = {};
      state.currentProject = {};
      state.sideBarList = {};
    },
    Client(state, info) {
      state.info = info;
    },
    allowedProjects(state, projects) {
      state.allowedProjects = projects;
    },
    currentProject(state, project) {
      state.currentProject = project;
    },
    displayHeader(state) {
      state.displayHeader = true;
    },
    sideBarList(state, list) {
      state.sideBarList = list;
    },
  },
  getters: {
    /** サインイン状態 */
    isSignin: (state) => {
      return state.isSignin;
    },
    /** ユーザー情報 */
    user: (state) => {
      return state.user.account;
    },
    /** idトークン
     */
    token: (state) => {
      return state.user.idToken;
    },
    /** 事前確認ページURL */
    privateUrl: (state) => {
      return state.info.clientPrivateUrl;
    },
    /** 本番公開ページURL */
    publicUrl: (state) => {
      return state.info.clientPublicUrl;
    },
    /** ユーザーに紐ずく案件情報 */
    allowedProjects: (state) => {
      return state.allowedProjects;
    },
    /** 操作中案件情報 */
    currentProject: (state) => {
      return state.currentProject;
    },
    /**  */
    displayHeader: (state) => {
      return state.displayHeader;
    },
    /** メニューバーリスト */
    sideBarList: (state) => {
      return state.sideBarList;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
