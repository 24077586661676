import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "@/Config.js";
import Logger from "./lib/Log/Logger";
import ConsoleOutput from "./lib/Log/ConsoleOutput";
import AuthManager from "./lib/AuthManager.js";
import StarkManager from "./lib/StarkManager.js";
import MorganAccessor from "./lib/MorganAccessor";
import vuetify from "./plugins/vuetify";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

const app = createApp(App).use(store).use(router).use(vuetify);
app.mount("#app");

// ロガークラスを定義
app.config.globalProperties.$Logger = new Logger(config.logger.baseConfig, [
  new ConsoleOutput(config.logger.consolelogConfig),
]);

// 認証管理クラスを定義
app.config.globalProperties.$AuthManager = new AuthManager({
  auth: config.msal.auth,
  cache: config.msal.cache,
});

// アクセサー管理クラスを定義
app.config.globalProperties.$StarkManager = new StarkManager(
  app.config.globalProperties.$AuthManager,
  {
    Morgan: new MorganAccessor(app.config.globalProperties.$Logger, {
      endpointSuffix: config.morganendpointurl,
    }),
  }
);
