/* 共通で使用するメソッドを定義します。*/

/**
 *Date型の日付を（例 2022年09月06日（火））このような形式に変換します。
 * @param  date 形式変換する日付
 * @return  format 形式変換した日程の文字列を返します。
 */
function formatDate(date) {
  date = new Date(date);
  // 今後フォーマットを指定できるように修正する可能性あり。
  var format = "yyyy年MM月dd日（w）";
  // 曜日配列
  var week = ["日", "月", "火", "水", "木", "金", "土"];
  var w = date.getDay();
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
  format = format.replace(/w/g, week[w]);
  return format;
}

/**
 *モバイルまたはタブレットでアクセスされたかを判定します。
 *@return 判定結果をtrue falseで返します。
 */
function isMobile() {
  var userAgent = window.navigator.userAgent.toLowerCase();
  if (
    userAgent.indexOf("iphone") != -1 ||
    userAgent.indexOf("ipad") != -1 ||
    userAgent.indexOf("android") != -1 ||
    userAgent.indexOf("mobile") != -1
  ) {
    return true;
  } else {
    return false;
  }
}

export default {
  formatDate,
  isMobile,
};
