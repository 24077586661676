<!--メッセージング画面-->
<template>
  <v-app>
    <div>
      <v-card class="bg-primary text-light text-left">メッセージ</v-card>
    </div>
    <v-card id="explanation" class="text-left">
      <ul>
        <li>
          配信サービススタッフとメッセージのやり取りを行うことができます。
        </li>
        <li>
          メッセージを記載するトピックを一覧から選択します。表示されたダイアログからメッセージを送信できます。
        </li>
        <li>メッセージにはファイルを添付することも可能です。</li>
        <li>
          新たにトピックを作成する場合は『トピックを作成』ボタンを押してください。
        </li>
      </ul>
    </v-card>
    <AddTopicBtn />
    <TopicTable />
  </v-app>
</template>

<script>
import TopicTable from "../components/Message/TopicTable.vue";
import AddTopicBtn from "../components/Message/AddTopicBtn.vue";
import CMethod from "../lib/CommonMethods.js";
export default {
  name: "Info",
  components: {
    TopicTable,
    AddTopicBtn,
  },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    // モバイルからのアクセスの場合、表示するコンポーネントを切り替える
    this.mobile = CMethod.isMobile();
  },
};
</script>
<style>
#explanation {
  margin: 20px;
  padding: 25px 0 10px 0;
  width: fit-content;
  background-color: #e6f1ff;
}
ul {
  list-style-type: circle;
}
</style>
