<!--サインイン画面-->
<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <h3 id="title">JFIT-CustomerPortal</h3>
        <SignInButton />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignInButton from "@/components/Common/SignInButton.vue";

export default {
  name: "SignInView",
  components: {
    SignInButton,
  },
};
</script>
<style>
#title {
  width: fit-content;
  color: #0b5ed7;
  padding: 0.5em 0;
  border-top: solid 3px #0b5ed7;
  border-bottom: solid 3px #0b5ed7;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  margin-bottom: 20px;
}
</style>
