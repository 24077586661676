<!--ライブボタン切替画面-->
<template>
  <v-app>
    <div class="button">
      <v-card class="bg-primary text-light text-left">ライブボタン切替</v-card>
    </div>
    <v-expansion-panels v-if="mobile">
      <v-expansion-panel>
        <v-expansion-panel-title> 使用方法 ▼ </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-card id="explanation" class="text-left">
            <ul>
              <li>配信サイトのライブボタンの切り替えを行います。</li>
              <li>
                『ライブボタン状態』 が 「<span class="text-primary fw-bold"
                  >有効</span
                >」 の場合、ライブボタンは有効になっています。
              </li>
              <li>
                「無効にする」
                をクリックすると、ライブボタンを無効にすることができます。
              </li>
              <li>
                『ライブボタン状態』 が 「<span class="fw-bold">無効</span>」
                の場合、ライブボタンは無効になっています。
              </li>
              <li>
                「有効にする」
                をクリックすると、ライブボタンを有効にすることができます。
              </li>
              <li>
                『ライブ再生ページ』
                のアイコンをクリックすると、ライブ再生ページの表示を確認できます。
              </li>
              <li>
                『公開サイトトップ』
                のアイコンをクリックすると、公開サイトトップページの表示を確認できます。
              </li>
            </ul>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card v-else id="explanation" class="text-left">
      <ul>
        <li>配信サイトのライブボタンの切り替えを行います。</li>
        <li>
          『ライブボタン状態』 が 「<span class="text-primary fw-bold"
            >有効</span
          >」 の場合、ライブボタンは有効になっています。
        </li>
        <li>
          「無効にする」
          をクリックすると、ライブボタンを無効にすることができます。
        </li>
        <li>
          『ライブボタン状態』 が 「<span class="fw-bold">無効</span>」
          の場合、ライブボタンは無効になっています。
        </li>
        <li>
          「有効にする」
          をクリックすると、ライブボタンを有効にすることができます。
        </li>
        <li>
          『ライブ再生ページ』
          のアイコンをクリックすると、ライブ再生ページの表示を確認できます。
        </li>
        <li>
          『公開サイトトップ』
          のアイコンをクリックすると、公開サイトトップページの表示を確認できます。
        </li>
      </ul>
    </v-card>
    <ControlTablemobile v-if="mobile" />
    <ControlTable v-else />
  </v-app>
</template>

<script>
import store from "../store";
import ControlTable from "../components/LiveBtnControll/ControlTable.vue";
import ControlTablemobile from "../components/LiveBtnControll/ControlTable_mobile.vue";
import CMethod from "../lib/CommonMethods.js";
export default {
  name: "Info",
  components: { ControlTable, ControlTablemobile },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    // モバイルからのアクセスの場合、表示するコンポーネントを切り替える
    this.mobile = CMethod.isMobile();
  },
};
</script>
<style>
#explanation {
  margin: 20px;
  padding: 25px 0 10px 0;
  width: fit-content;
  background-color: #e6f1ff;
}
ul {
  list-style-type: circle;
}
</style>
