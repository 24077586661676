<!--ログイン後、welcomeページへ遷移する前の中間画面-->
<!--ログインしたユーザー情報とそれに紐づく案件情報、表示するメニュー項目をここでセットします。-->
<template>
  <div>
    <Loading id="loader" />
  </div>
</template>

<script>
import store from "@/store/index.js";
import router from "@/router/index.js";
import Loading from "../components/Common/Loading.vue";
export default {
  name: "Load",
  components: { Loading },
  methods: {
    /**
     * サインイン後、初期表示に必要なデータをセットします。
     */
    async fetchInitialData() {
      // 顧客情報をセット
      this.$StarkManager.getMember("Morgan").fetchClient();
      //案件情報をセット
      await this.$StarkManager.getMember("Morgan").fetchProject();
      //メニュー項目をセット
      await this.$StarkManager.getMember("Morgan").fetchMenu();

      // 初期表示データセットに成功した場合
      if (
        !Object.keys(store.getters.allowedProjects).length == 0 &&
        !Object.keys(store.getters.sideBarList).length == 0
      ) {
        // 初期ページ(Welcome)へ遷移
        store.commit("displayHeader", true);
        router.push(
          "/welcome",
          () => {},
          () => {}
        );
      } else {
        //サインイン画面へ戻る
        alert(
          "ユーザー情報の取得に失敗しました。サインインページへ遷移します。"
        );
        this.$AuthManager.SignOut();
      }
    },
  },
  mounted() {
    this.fetchInitialData();
  },
};
</script>
<style>
#loader {
  margin: 150px;
}
</style>
