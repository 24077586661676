/**
 * コンソール出力を行うクラスです。
 */
export default class ConsoleOutput {
  constructor(config) {
    this.config = config;
  }

  /**
   * コンソールにトレース情報を出力します。
   * @param message メッセージ
   * @param value 値
   */
  trace(message, value) {
    if (this.config.enable && this.config.levelFilter.includes("trace")) {
      console.trace(message, value);
    }
  }
  /**
   * コンソールにデバッグ情報を出力します。
   * @param message メッセージ
   * @param value 値
   */
  debug(message, value) {
    if (this.config.enable && this.config.levelFilter.includes("debug")) {
      console.debug(message, value);
    }
  }
  /**
   * コンソールに情報を出力します。
   * @param message メッセージ
   * @param value 値
   */
  info(message, value) {
    if (this.config.enable && this.config.levelFilter.includes("info")) {
      console.info(message, value);
    }
  }
  /**
   * コンソールに警告を出力します。
   * @param message メッセージ
   * @param value 値
   */
  warn(message, value) {
    if (this.config.enable && this.config.levelFilter.includes("warn")) {
      console.warn(message, value);
    }
  }
  /**
   * コンソールにエラーを出力します。
   * @param message メッセージ
   * @param value 値
   */
  error(message, value) {
    if (this.config.enable && this.config.levelFilter.includes("error")) {
      console.error(message, value);
    }
  }
  /**
   * コンソールに致命的なエラーを出力します。
   * @param message メッセージ
   * @param value 値
   */
  fatal(message, value) {
    if (this.config.enable && this.config.levelFilter.includes("fatal")) {
      console.fatal(message, value);
    }
  }
}
