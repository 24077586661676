<!--サインインボタンのコンポーネントです。-->
<template>
  <v-btn
    id="button"
    color="primary"
    elevation="5"
    title="サインイン"
    @click="SignIn"
    v-if="!isSignin"
  >
    <img src="./icons/box-arrow-in-right.svg" alt="siginin" />
  </v-btn>
</template>

<script>
import store from "@/store/index.js";

export default {
  name: "SignInButton",
  methods: {
    /**
     * ボタン押下時処理
     * サインインポップアップを表示します。
     */
    SignIn() {
      this.$AuthManager.SignInPopup();
    },
  },
  computed: {
    isSignin() {
      return store.getters.isSignin;
    },
  },
};
</script>
<style>
#button {
  width: 65px;
  height: 55px;
}
</style>
