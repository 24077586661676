<!--アプリの読み込み中に表示するスピナーのコンポーネントです。-->
<template>
  <div id="app">
    <div id="spinner" class="spinner-border text-primary" role="status"></div>
    <div>読み込み中</div>
  </div>
</template>

<script></script>

<style></style>
