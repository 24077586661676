<!--メッセージ送信およびトピックの新規作成を行うダイアログのコンポーネントです。-->
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-dialog
          fullscreen
          id="MM"
          v-model="dialogOpen"
          @click:outside="
            close();
            clear();
          "
          @keydown.esc="clear()"
        >
          <!-- トピック追加ダイアログ -->
          <v-card
            id="MC"
            class="overflow-auto"
            v-if="modalitem == null"
            style="width: 696px; height: 655px"
          >
            <h4 style="margin: 10px">新しいトピックを作成</h4>

            <v-card-text>
              <v-text-field
                label="件名"
                v-model="topic"
                style="width: 650px"
              ></v-text-field>
            </v-card-text>

            <h4 style="margin: 15px">最初のメッセージ</h4>

            <v-card-text>
              <div>
                <v-textarea
                  v-model="comment"
                  label="メッセージを入力"
                ></v-textarea>
                <div class="add-filearea" style="display: flex">
                  <div class="file" style="width: 96%">
                    <v-btn
                      v-for="file in fileobj"
                      :key="file"
                      variant="outlined"
                      color="primary"
                      style="
                        margin-left: 20px;
                        height: 48px;
                        text-transform: none;
                      "
                    >
                      <img
                        src="./icons/file-earmark.svg"
                        width="16"
                        height="16"
                        alt="file"
                      />
                      {{ file.name }}
                      　{{ file.size }}byte

                      <img
                        src="./icons/trash3.svg"
                        @click.stop="removeFile(index)"
                        alt="delete"
                      />
                    </v-btn>
                  </div>
                  <div
                    class="inputbtn"
                    style="width: 20%; margin-left: 10px"
                    title="ファイルを添付"
                  >
                    <label class="file-select-labbel">
                      <img
                        src="./icons/paperclip.svg"
                        width="25"
                        height="25"
                        alt="clip"
                      />
                      <input hidden type="file" multiple @change="chooseFile" />
                    </label>
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  this.$parent.dialogOpen = false;
                  clear();
                "
                class="btn-secondary"
                >キャンセル</v-btn
              >
              <v-btn
                @click="
                  addTopic(modalitem);
                  close();
                  clear();
                "
                class="btn-primary"
                >作成</v-btn
              >
            </v-card-actions>
          </v-card>

          <!-- メッセージダイアログ -->
          <v-card
            id="MC"
            class="overflow-auto"
            v-else
            style="width: 1050px; height: 880px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
              style="float: right; margin: 7px 12px 0 0; cursor: pointer"
              title="閉じる"
              @click="close"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
            <v-card-title>
              <div style="margin: 10px">
                {{ modalitem.create_time }}　{{ modalitem.topic }}
              </div>
            </v-card-title>
            <h4 style="margin: 10px">メッセージ</h4>
            <v-divider></v-divider>
            <v-card-text>
              <div>
                <v-textarea
                  v-model="comment"
                  label="メッセージを入力"
                ></v-textarea>
                <v-btn
                  icon
                  @click="Testselect(modalitem)"
                  class="btn-primary"
                  style="display: block; margin: -30px 0 10px auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-send"
                    viewBox="0 0 16 16"
                    transform="rotate(45)"
                  >
                    <path
                      d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"
                    /></svg
                ></v-btn>

                <div class="add-filearea" style="display: flex">
                  <div class="file" style="width: 96%">
                    <v-btn
                      v-for="file in fileobj"
                      :key="file"
                      variant="outlined"
                      color="primary"
                      style="
                        margin-left: 20px;
                        height: 48px;
                        text-transform: none;
                      "
                    >
                      <img
                        src="./icons/file-earmark.svg"
                        width="16"
                        height="16"
                        alt="file"
                      />
                      {{ file.name }}
                      　{{ file.size }}byte

                      <img
                        src="./icons/trash3.svg"
                        @click.stop="removeFile(index)"
                        alt="delete"
                      />
                    </v-btn>
                  </div>
                  <div class="inputbtn" style="width: 20%; margin-left: 10px">
                    <label class="file-select-labbel">
                      添付ファイルを追加
                      <input hidden type="file" multiple @change="chooseFile" />
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="comment"
                  style="margin-bottom: 20px; margin-top: 10px; display: flex"
                  v-for="come in modalitem.comment"
                  :key="come"
                >
                  <div
                    style="
                      width: 20%;
                      font-size: 12px;
                      align-items: flex-end;
                      display: flex;
                    "
                  >
                    {{ come.create_user }} <br />{{ come.time }}
                  </div>
                  <div style="width: 50%" v-show="come.text != ''">
                    {{ come.text }}
                  </div>
                  <div style="width: 30%">
                    <span style="text-align: end">
                      <v-btn
                        v-for="file in come.files"
                        :key="file"
                        variant="outlined"
                        color="primary"
                        style="
                          margin-left: 20px;
                          height: 48px;
                          text-transform: none;
                        "
                      >
                        <img
                          src="./icons/file-earmark.svg"
                          width="16"
                          height="16"
                          alt="file"
                          style="margin-right: 5px"
                        />
                        {{ file.originalName }}
                        　{{ file.size }}byte
                      </v-btn>
                    </span>
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  dialogOpen2 = false;
                  reset();
                "
                class="btn-secondary"
                >キャンセル</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- アップモーダル -->
        <!-- <FileUpload @getSubjects="getSubjects" /> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store/index.js";
import builder from "@/lib/CommonMethods.js";
import axios from "axios";
import Loading from "../Common/Loading.vue";
import AddTopicDialod from "./Dialog.vue";

export default {
  props: ["dialogOpen", "modalitem"],
  components: {
    Loading,
    AddTopicDialod,
  },
  data() {
    return {
      status: null,
      subjects: null,
      selected: [],

      //TEST
      subjectsX: null,
      //modalitem: dialogitem,
      scroll: true,
      // dialogOpen: false, //件名
      // dialogOpen2: false, //メッセージ
      // dialogOpen3: false, //削除
      fileobj: [],
      fileinfo: [],
      topic: "",
      comment: "",
      deletetopic: "",
    };
  },
  methods: {
    ////// Test ////////
    addTopic() {
      if (this.topic != "") {
        axios
          .post("http://localhost:3000/t_subjectX", {
            topic: this.topic,
            create_time: new Date().toLocaleString(),
            comment: [],
          })
          .then((response) => {
            console.log(response.data);
            this.getSubjectsX();
            this.$parent.dialogOpen = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    close() {
      this.$parent.dialogOpen = false;
    },

    clear() {
      this.topic = "";
      this.comment = "";
      this.fileinfo = [];
      this.fileobj = [];
    },

    Testselect(data) {
      this.getSubjectsX();
      if (this.comment != "" || this.fileobj.length != 0) {
        let comO = data.comment;
        console.log(data.comment);

        let comeData = {
          time: new Date().toLocaleString(),
          text: this.comment,
          create_user: store.getters.name,
          files: this.fileinfo,
        };

        comO.push(comeData);

        axios
          .put("http://localhost:3000/t_subjectX/" + data.id, {
            topic: data.topic,
            create_time: new Date().toLocaleString(),
            comment: comO,
          })
          .then((response) => {
            console.log(response.data);

            this.clear();
            // 全件名再取得し、一覧を更新する
            this.getSubjectsX();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getSubjectsX() {
      axios
        .get("http://localhost:3000/t_subjectX?_sort=id&_order=desc")
        .then((response) => {
          console.log(response.data);
          this.subjectsX = response.data;
          console.log(this.subjectsX);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    TestOpen(item) {
      this.modalitem = item;
      //document.documentElement.style.overflow = "hidden";
    },

    dragleave() {
      this.drag = false;
      this.fileobj = [];
    },

    // ファイル情報をリストに格納
    setFile(data) {
      this.drag = false;
      for (var i = 0; i < data.length; i++) {
        // ファイルオブジェクトをリストに格納
        this.fileobj.push(data[i]);

        // ファイル情報をリストに格納
        var addData = {
          originalName: data[i].name,
          size: data[i].size,
        };
        this.fileinfo.push(addData);
      }
    },

    removeFile(index) {
      this.fileobj.splice(index, 1);
      this.fileinfo.splice(index, 1);
    },

    dropFile(e) {
      var data = e.dataTransfer.files;
      this.setFile(data);
    },
    chooseFile(e) {
      var data = e.target.files;
      this.setFile(data);
    },

    confirmationDelete(topic) {
      this.dialogOpen3 = true;
      this.deletetopic = topic;
    },

    deleteTopic() {
      axios
        .delete("http://localhost:3000/t_subjectX/" + this.deletetopic.id)
        .then((response) => {
          console.log(response.data);
          this.deletetopic = "";
          // 全件名再取得し、一覧を更新する
          this.getSubjectsX();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ////// Test ////////
    getSubjects() {
      axios
        .get("http://localhost:3000/t_subject?_sort=id&_order=desc")
        .then((response) => {
          console.log(response.data);
          // store.commit("setSubjects", response.data);
          // console.log(store.getters.subjects);
          this.subjects = response.data;
          this.status = response.status;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select() {
      //this.selected = [];
      for (let i in this.selected) {
        //this.selected.push(this.selected[i]);
        console.log(this.selected[i]);
      }
    },
    //
    // clientid取得
    //
    getclientid() {
      return store.getters.currentproject.projectId;
    },
  },
  mounted() {
    // 件名一覧取得
    this.getSubjects();
    this.getSubjectsX();
  },
};
</script>

<style>
.subjectlist {
  width: 80%;
  margin: 0 0 0 20px;
}
.subjectlist tr :hover,
.delete-icon {
  cursor: pointer;
}

.subdate {
  text-align: left !important;
  width: 408px !important;
  margin: 20px 0 0 0;
}
.subname {
  text-align: left !important;
  width: 545px;
}
.titles {
  text-align: left;
  background-color: white;
}
.comment {
  text-align: left;
  margin: 20px 0 -20px 0;

  /*TODO 仮*/
  border-bottom: solid 1px rgb(216, 214, 214);
}

/*TODO 仮*/
table td:last-child {
  width: 195px;
}
.bc {
  background-color: cornflowerblue;
}

.upload-btn {
  color: white !important;
  background-color: #41b883;
}
.drag-area {
  min-height: 300px;
  max-width: 500px;
  width: 100%;
  border: 1px dashed #ced4da;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enter.drag-area {
  border: 2px dashed #1867c0;
  background: #d4e8ff;
}
.drag-title {
  color: cornflowerblue;
}
.send-file-info {
  padding: 20px 0 0 0 !important;
}
.file-select-labbel {
  margin: 6px 0;
  padding: 10px 21px;
  border-radius: 6px;
  color: #ffffff;
  background-color: #1564d9;
  cursor: pointer;
}

#MC {
  width: 1050px;
  height: 880px;
  /* height: auto; */
}

.v-dialog .v-overlay__content {
  max-height: 930px;
  min-width: 240px;
  max-width: 1050px;
  border-radius: 4px;
}
</style>
